//音频相关的方法
import { wrap } from '../bbtNative';
const Audio = {
    //初始化客户端要回调前端的方法
    init(options) {
        if (options &&
            Object.prototype.toString.call(options) === '[object Object]') {
            /**
             * 客户端回调到WebView的js-音频播放状态方法，  调用场景:
             *          1、当webview页面加载完毕后客户端会检测当前业务的音频在播放状态时调用，
             *          2、页面重现时客户端会检测当前业务的音频在播放状态时调用
             *          3、当前业务的音频在播放状态发生变化时调用
             *
             * @param audio_flag 当前播放音频所属的业务标签  亲子陪伴业务:"audio_flag_parent_son_company"
             * @param id     音频id, 此处代表文章id不局限文章id  代表每一条音频的唯一标示？   如果当前业务没有音频播放为 "" 空字符串
             * @param type    音频业务类型，此处代表文章类型不局限文章类型                   如果当前业务没有音频播放为 "" 空字符串
             * @param path String 音频的播放路径 path也可用于校验定位播放的音频视图          如果当前业务没有音频播放为 "" 空字符串
             * @param status String 音频的播放状态（数值可商议）：
             *            0、normal（正常状态）     如果当前业务没有音频播放为状态或者当前业务有音频单未播放
             *            1、preparing（准备状态）
             *            2、prepared（准备完成状态）
             *            3、playing（播放状态）
             *            4、pause（暂停状态）
             *            5、complete（播放完成状态）
             *            6、error（播放异常状态）
             *            7、release （播放结束或播放停止的状态）   如果当前业务有音频已经播放停止或结束
             */
            window.nativeResponseAudioStatus = (audio_flag, id, type, path, status) => {
                options.audioStatusCB &&
                    options.audioStatusCB({
                        audio_flag,
                        id,
                        type,
                        path,
                        status
                    });
            };
            /**
             * 客户端回调到WebView的js-音频播放进度方法，  调用场景:
             *          1、客户端当前业务的音频播放进度变化时调用
             *
             * @param audio_flag 当前播放音频所属的业务标签  亲子陪伴业务:"audio_flag_parent_son_company"
             * @param id     音频id, 此处代表文章id不局限文章id  代表每一条音频的唯一标示？
             * @param type    音频业务类型，此处代表文章类型不局限文章类型
             * @param path String 音频的播放路径 path也可用于校验定位播放的音频视图
             * @param progress String 音频播放的百分比，  1-100 数字
             * @param position String 当前播放位置 毫秒
             * @param duration String 音频总时长 毫秒
             */
            window.nativeResponseAudioProgress = (audio_flag, id, type, path, progress, position, duration) => {
                options.audioProgressCB &&
                    options.audioProgressCB({
                        audio_flag,
                        id,
                        type,
                        path,
                        progress,
                        position,
                        duration
                    });
            };
        }
    },
    /**
     * 加载音频js，调用后会强制更新播放的音频
     * @param audio_flag 当前播放音频所属的业务标签,存在的意义是因为audio_list中的id,类型与业务强相关，要用来做上报，为了以后其他业务使用需要区分  亲子陪伴业务:"audio_flag_parent_son_company"
     * @param audio_list String, 包含音频播放列表的JsonArray字符串（需要每一条音频的唯一标示，音频的名称，音频的播放路径）
     *                   每个item包括：
     *                          id     音频id, 此处代表文章id不局限文章id  代表每一条音频的唯一标示？
     *                          type    音频业务类型，此处代表文章类型不局限文章类型
     *                          musicName  音频名称
     *                          musicUrl  音频链接
     *                          musicRouterPath  音频点击跳转路由或链接
     *                          musicIcon  音频icon
     * @param position String, 当前音频在列表里面的位置
     */
    load(audio_flag, audio_list, position) {
        wrap(function () {
            if (window.Bridge && window.Bridge.postMessage) {
                window.Bridge.postMessage({
                    method: 'nativeAudioLoad',
                    params: [audio_flag, audio_list, position]
                });
                return true;
            }
            if (window.nativeAudioLoad) {
                window.nativeAudioLoad(audio_flag, audio_list, position);
                return true;
            }
            if (window.android && window.android.nativeAudioLoad) {
                window.android.nativeAudioLoad(audio_flag, audio_list, position);
                return true;
            }
            return false;
        });
    },
    /**
     * 播放音频js，调用后如果音频播放暂停状态则恢复播放，音频非播放状态时则重新播放
     * @param audio_flag 当前播放音频所属的业务标签,存在的意义是因为audio_list中的id,类型与业务强相关，要用来做上报，为了以后其他业务使用需要区分  亲子陪伴业务:"audio_flag_parent_son_company"
     * @param audio_list String, 包含音频播放列表的JsonArray字符串（需要每一条音频的唯一标示 id、音频的名称 name、音频的播放路径 path）
     * @param position String, 当前音频在列表里面的位置
     */
    play(audio_flag, audio_list, position) {
        wrap(function () {
            if (window.Bridge && window.Bridge.postMessage) {
                window.Bridge.postMessage({
                    method: 'nativeAudioPlay',
                    params: [audio_flag, audio_list, position]
                });
                return true;
            }
            if (window.nativeAudioPlay) {
                window.nativeAudioPlay(audio_flag, audio_list, position);
                return true;
            }
            if (window.android && window.android.nativeAudioPlay) {
                window.android.nativeAudioPlay(audio_flag, audio_list, position);
                return true;
            }
            return false;
        });
    },
    /**
       暂停音频js，调用后如果音频播放状态则暂停音频，音频非暂停态时则释放音频
       * @param audio_flag 当前播放音频所属的业务标签,存在的意义是因为audio_list中的id,类型与业务强相关，要用来做上报，为了以后其他业务使用需要区分  亲子陪伴业务:"audio_flag_parent_son_company"
       * @param id String 音频的唯一标示    此处代表文章id如果是唯一表示    可以为空字符串，如果为空字符串 代表暂停的是当前audio_flag业务下的所有音频，并非暂停某一个
       * @param type String 音频业务类型，此处代表文章类型不局限文章类型     可以为空字符串，如果为空字符串 代表暂停的是当前audio_flag业务下的所有音频，并非暂停某一个
       * @param path String 音频的播放路径 path                         可以为空字符串，如果为空字符串 代表暂停的是当前audio_flag业务下的所有音频，并非暂停某一个
       */
    pause(audio_flag, id, type, path) {
        wrap(function () {
            if (window.Bridge && window.Bridge.postMessage) {
                window.Bridge.postMessage({
                    method: 'nativeAudioPause',
                    params: [audio_flag, id, type, path]
                });
                return true;
            }
            if (window.nativeAudioPause) {
                window.nativeAudioPause(audio_flag, id, type, path);
                return true;
            }
            if (window.android && window.android.nativeAudioPause) {
                window.android.nativeAudioPause(audio_flag, id, type, path);
                return true;
            }
            return false;
        });
    },
    /**
     * 音频进度调整js
     * @param audio_flag 当前播放音频所属的业务标签,存在的意义是因为audio_list中的id,类型与业务强相关，要用来做上报，为了以后其他业务使用需要区分  亲子陪伴业务:"audio_flag_parent_son_company"
     * @param id String 音频的唯一标示    此处代表文章id如果是唯一表示
     * @param type String 音频业务类型，此处代表文章类型不局限文章类型
     * @param path String 音频的播放路径 path
     * @param position String 音频seek到的位置，毫秒
     */
    seek(audio_flag, id, type, path, position) {
        wrap(function () {
            if (window.Bridge && window.Bridge.postMessage) {
                window.Bridge.postMessage({
                    method: 'nativeAudioSeek',
                    params: [audio_flag, id, type, path, position]
                });
                return true;
            }
            if (window.nativeAudioSeek) {
                window.nativeAudioSeek(audio_flag, id, type, path, position);
                return true;
            }
            if (window.android && window.android.nativeAudioSeek) {
                window.android.nativeAudioSeek(audio_flag, id, type, path, position);
                return true;
            }
            return false;
        });
    },
    /**
     * 隐藏当前页面的悬浮窗
     */
    hideAudioWindow() {
        wrap(function () {
            if (window.Bridge && window.Bridge.postMessage) {
                window.Bridge.postMessage({
                    method: 'nativeAudioWindowHide',
                    params: []
                });
                return true;
            }
            if (window.nativeAudioWindowHide) {
                window.nativeAudioWindowHide();
                return true;
            }
            if (window.android && window.android.nativeAudioWindowHide) {
                window.android.nativeAudioWindowHide();
                return true;
            }
            return false;
        });
    },
    /**
     * 显示当前页面的悬浮窗（如果有悬浮窗可以展示的话）
     */
    showAudioWindow() {
        wrap(function () {
            if (window.Bridge && window.Bridge.postMessage) {
                window.Bridge.postMessage({
                    method: 'nativeAudioWindowShow',
                    params: []
                });
                return true;
            }
            if (window.nativeAudioWindowShow) {
                window.nativeAudioWindowShow();
                return true;
            }
            if (window.android && window.android.nativeAudioWindowShow) {
                window.android.nativeAudioWindowShow();
                return true;
            }
            return false;
        });
    },
    /**
     * 检测当前业务的播放状态 客户端收到后会回调函数：nativeResponseAudioStatus
     * @param audio_flag 当前播放音频所属的业务标签,存在的意义是因为audio_list中的id,类型与业务强相关，要用来做上报，为了以后其他业务使用需要区分  亲子陪伴业务:"audio_flag_parent_son_company"
     */
    checkStatus(audio_flag) {
        wrap(function () {
            if (window.Bridge && window.Bridge.postMessage) {
                window.Bridge.postMessage({
                    method: 'nativeAudioCheckStatus',
                    params: [audio_flag]
                });
                return true;
            }
            if (window.nativeAudioCheckStatus) {
                window.nativeAudioCheckStatus(audio_flag);
                return true;
            }
            if (window.android && window.android.nativeAudioCheckStatus) {
                window.android.nativeAudioCheckStatus(audio_flag);
                return true;
            }
            return false;
        });
    },
    /**
     * 使原生音频视频失去焦点
     */
    focusLoss() {
        wrap(function () {
            if (window.Bridge && window.Bridge.postMessage) {
                window.Bridge.postMessage({
                    method: 'nativeAudioFocusLoss',
                    params: []
                });
                return true;
            }
            if (window.nativeAudioFocusLoss) {
                window.nativeAudioFocusLoss();
                return true;
            }
            if (window.android && window.android.nativeAudioFocusLoss) {
                window.android.nativeAudioFocusLoss();
                return true;
            }
            return false;
        });
    }
};
export default Audio;
