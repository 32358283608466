import { wrap } from '../bbtNative';
/**
 *@apiDescription openMiniProgram方法：webview调起小程序（>=7.9.0）
 *@apiParam path:  {string} 调起小程序的具体路径
 *@apiParam appid:  {string}  调起小程序的appid
 *eg: openMiniProgram('pages/details/index?id=3333&share=1','gh_7446aad5912f')
 */
export default function openMiniProgram(path, appid) {
    wrap(function () {
        if (!appid) {
            throw new Error('appid不能为空');
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'openMiniProgram',
                params: [path, appid]
            });
            return true;
        }
        if (window.openMiniProgram && typeof window.openMiniProgram == 'function') {
            window.openMiniProgram(path, appid);
            return true;
        }
        if (window.android && window.android.openMiniProgram) {
            window.android.openMiniProgram(path, appid);
            return true;
        }
        return false;
    });
}
