import { wrap } from '../bbtNative';
export default function (options) {
    if (typeof options !== 'object' || typeof options.callback !== 'function') {
        throw new Error('uploadPhoto options参数类型错误，需要包含callback方法');
    }
    wrap(function () {
        const originalUploadPhotoFinishCall = window.uploadPhotoFinishCall;
        window.uploadPhotoFinishCall = function (status, sessionId, data) {
            options.callback(status, sessionId, data);
            window.uploadPhotoFinishCall = originalUploadPhotoFinishCall;
        };
        const sessionId = options.sessionId || +new Date() + '';
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'uploadPhotoByBase64',
                params: [sessionId]
            });
            return true;
        }
        if (window.uploadPhotoByBase64 &&
            typeof window.uploadPhotoByBase64 == 'function') {
            window.uploadPhotoByBase64(sessionId);
            return true;
        }
        if (window.android && window.android.uploadPhotoByBase64) {
            window.android.uploadPhotoByBase64(sessionId);
            return true;
        }
        return false;
    });
}
