import { wrap } from '../bbtNative';
/**
 * 打开登录页
 * @alias bbtNative.login
 */
export default function login(source) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeLogin',
                params: ['ask_reload_webview', 'ask_reload_webview', source]
            });
            return true;
        }
        if (window.nativeLogin) {
            window.nativeLogin('ask_reload_webview', 'ask_reload_webview', source);
            return true;
        }
        if (window.android && window.android.nativeLogin) {
            window.android.nativeLogin('ask_reload_webview', 'ask_reload_webview', source);
            return true;
        }
        return false;
    });
}
