import { wrap } from '../bbtNative';
/**
 *@apiDescription launchAddThemePage方法：加入主题弹框 （7.9.15）
 *@apiParam contentId:  {string}  内容id
 *@apiParam contentType:  {string}  内容类型 1-达人文章 2-帖子 3-知识 4-问答 5-专家答 6-妈妈看 7-专家开讲 8-关注人发布文章 9-关注人发布帖子 10-关注人回答问题 11-关注人转发文章 12-关注人转发帖子 13-关注人转发知识 14-关注人转发问答 15-首页达人 16-主题主页可能感兴趣的人
 *@apiParam status:  {string}  '0'未收藏；‘1’已收藏   //8.8.0加的这个参数
 */
export default function launchAddThemePage(contentId, contentType, status) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'launchAddThemePage',
                params: [contentId, contentType, status]
            });
            return true;
        }
        if (window.launchAddThemePage &&
            typeof window.launchAddThemePage == 'function') {
            window.launchAddThemePage(contentId, contentType, status);
            return true;
        }
        if (window.android && window.android.launchAddThemePage) {
            window.android.launchAddThemePage(contentId, contentType, status);
            return true;
        }
        return false;
    });
}
