/**
 * Get query from url by name.
 * @param {String} name Query name.
 * @param {String} url Optional,the url.
 * @returns {String} Query value.
 */
export function getQuery(name, url = window.location.href) {
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
    if (!results)
        return '';
    if (!results[2])
        return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
/**
 * Add or update query string.
 * @param {String} name Query name.
 * @param {String} value Query value
 * @param {String} url Optional,the url.
 * @returns {String} Query value.
 */
export function addOrUpdateQuery(name, value, url = window.location.href) {
    var regexp = new RegExp('([?&])' + name + '=.*?(&|#|$)(.*)', 'gi'), hash;
    if (regexp.test(url)) {
        if (typeof value !== 'undefined' && value !== null)
            return url.replace(regexp, '$1' + name + '=' + value + '$2$3');
        else {
            hash = url.split('#');
            url = hash[0].replace(regexp, '$1$3').replace(/(&|\?)$/, '');
            if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                url += '#' + hash[1];
            return url;
        }
    }
    else {
        if (typeof value !== 'undefined' && value !== null) {
            var separator = url.indexOf('?') !== -1 ? '&' : '?';
            hash = url.split('#');
            url = hash[0] + separator + name + '=' + value;
            if (typeof hash[1] !== 'undefined' && hash[1] !== null)
                url += '#' + hash[1];
            return url;
        }
        else
            return url;
    }
}
/**
 * deserialize query string.
 * */
export function getQueries() {
    const params = {};
    const queries = location.search.slice(1).split('&');
    // Convert the array of strings into an object
    for (let i = 0; i < queries.length; i++) {
        let temp = queries[i].split('=');
        params[temp[0]] = temp[1];
    }
    return params;
}
/**
 * serialize an object to url params.
 * @param {any} obj
 */
export function serialize(obj) {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    return str.join('&');
}
