import { wrap } from '../bbtNative';
/**
 *@apiDescription nativeGetEncyclopedia方法：孕育百科关键字弹层
 *@apiParam id:  {string}  知识中关键字点击id
 *@apiParam callback:  {function}  成功的回调函数
 */
export default function nativeGetEncyclopedia(id, callback) {
    wrap(function () {
        if (callback && typeof callback == 'function') {
            window.nativeGetEncyclopediaFinishCall = function (id, title, content) {
                callback(id, title, content);
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeGetEncyclopedia',
                params: [id]
            });
            return true;
        }
        if (window.nativeGetEncyclopedia &&
            typeof window.nativeGetEncyclopedia == 'function') {
            window.nativeGetEncyclopedia(id);
            return true;
        }
        if (window.android && window.android.nativeGetEncyclopedia) {
            window.android.nativeGetEncyclopedia(id);
            return true;
        }
        return false;
    });
}
