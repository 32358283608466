import { wrap } from '../bbtNative';
/**
 *@apiDescription getAliABTest方法：获取阿里分桶信息
 *@apiParam callback:  {function}  成功的回调函数
 */
export default function getAliABTest(callback) {
    wrap(function () {
        if (callback && typeof callback == 'function') {
            window.getAliABTestCallBack = function (type, sampleID) {
                callback({
                    type,
                    sampleID
                });
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'getAliABTest',
                params: []
            });
            return true;
        }
        if (window.getAliABTest && typeof window.getAliABTest == 'function') {
            window.getAliABTest();
            return true;
        }
        if (window.android && window.android.getAliABTest) {
            window.android.getAliABTest();
            return true;
        }
        return false;
    });
}
