import 'core-js/es/map';
import 'core-js/es/set';
/**
 * 事件触发都是同步的
 */
class Dispatcher {
    constructor() {
        this.map = new Map();
    }
    on(type, listener) {
        if (typeof type !== 'string' || !type) {
            throw new Error('type参数必须为有效的非空字符串');
        }
        if (typeof listener !== 'function') {
            throw new Error('listener参数必须为有效的function');
        }
        if (!this.map.has(type)) {
            this.map.set(type, new Set());
        }
        const set = this.map.get(type);
        set.add(listener);
    }
    off(type, listener) {
        if (typeof type !== 'string' || !type) {
            throw new Error('type参数必须为有效的非空字符串');
        }
        // listener可为空
        if (listener && typeof listener !== 'function') {
            throw new Error('如果传递listener参数，则listener参数必须为有效的function');
        }
        const set = this.map.get(type);
        if (!set) {
            return;
        }
        if (listener) {
            set.delete(listener);
            if (set.size === 0) {
                this.map.delete(type);
            }
        }
        else {
            this.map.delete(type);
        }
    }
    one(type, listener) {
        if (typeof type !== 'string' || !type) {
            throw new Error('type参数必须为有效的非空字符串');
        }
        if (typeof listener !== 'function') {
            throw new Error('listener参数必须为有效的function');
        }
        const that = this;
        const listenerWrap = function () {
            listener.apply(this, arguments);
            that.off(type, listenerWrap);
        };
        this.on(type, listenerWrap);
    }
    emit(type, args, ctx) {
        if (typeof type !== 'string' || !type) {
            throw new Error('type参数必须为有效的非空字符串');
        }
        // args可为空
        if (args && !Array.isArray(args)) {
            throw new Error('如果传递args参数，则args参数必须为有效的Array');
        }
        const set = this.map.get(type);
        if (!set) {
            return;
        }
        for (const listener of set) {
            try {
                listener.apply(Object(ctx), args);
            }
            catch (ex) {
                window.console.error(ex);
            }
        }
    }
}
const dispatcher = new Dispatcher();
export default dispatcher;
