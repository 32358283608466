import { wrap } from '../bbtNative';
export default function openNativeTool(type, name, url) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'openNativeTool',
                params: [type, name, url]
            });
            return true;
        }
        if (window.openNativeTool) {
            window.openNativeTool(type, name, url);
            return true;
        }
        if (window.android && window.android.openNativeTool) {
            window.android.openNativeTool(type, name, url);
            return true;
        }
        return false;
    });
}
