import login from './login';
import encryptApi from './encryptApi';
import { showFetusActionFloatWindow, closeFetusActionFloatWindow, getNativeFetusActionData, nativeFetusActionSoundSwitch, notifyH5MoveMentPlayStatus, notifyShowMovementFloatingBtn } from './fetalMovement';
import forcedLogoutAccount from './forcedLogoutAccount';
import setTitle from './setTitle';
import openWebview from './openWebview';
import closeWebview from './closeWebview';
import previewImage from './previewImage';
import videoPlay from './videoPlay';
import { setShareContent, showShareButton, hideShareButton, share } from './share';
import call from './call';
import catchPhoto from './catchPhoto';
import uploadPhoto from './uploadPhoto';
import scanQR from './scanQR';
import openScheme from './openScheme';
import openQRScanner4BindUser from './openQRScanner4BindUser';
import getDeviceInfo from './getDeviceInfo';
import downloadBase64ImgToAlbum from './downloadBase64ImgToAlbum';
import shareSinglePlatform from './shareSinglePlatform';
import nativeCollectStatus from './nativeCollectStatus';
import nativeShowCollectStatus from './nativeShowCollectStatus';
import updateRemindTaskStatus from './updateRemindTaskStatus';
import openMiniProgram from './openMiniProgram';
import shareWeixinMini from './shareWeixinMini';
import getAliABTest from './getAliABTest';
import launchAddThemePage from './launchAddThemePage';
import launchRelativeThemePage from './launchRelativeThemePage';
import nativeLaunchReply from './nativeLaunchReply';
import getMeitunToken from './getMeitunToken';
import getNativePushAction from './getNativePushAction';
import nativeSharePopup from './nativeSharePopup';
import nativeLocation from './nativeLocation';
import nativeLuckPopup from './nativeLuckPopup';
import updateBindUserStateSuccess from './updateBindUserStateSuccess';
import openMeitunReceivingAddress from './openMeitunReceivingAddress';
import nativeSwipeBack from './nativeSwipeBack';
import getNeighborInfo from './getNeighborInfo';
import getPhotoBase64 from './getPhotoBase64';
import uploadPhotoByBase64 from './uploadPhotoByBase64';
import nativeUpdateEnergy from './nativeUpdateEnergy';
import nativeParlorClose from './nativeParlorClose';
import getCmsABTest from './getCmsABTest';
import nativeCreateTopic from './nativeCreateTopic';
import nativeGetEncyclopedia from './nativeGetEncyclopedia';
import motherWatchAdAction from './motherWatchAdAction';
import openNativeTool from './openNativeTool';
import loadWebViewATAD from './loadWebViewATAD';
import nativeGetNetState from './nativeGetNetState';
import nativeBafFBFeedBack from './nativeBafFBFeedBack';
import nativePushAction from './nativePushAction';
import nativeAudio from './Audio';
import getFloatingWindowAuthState from './getFloatingWindowAuthState';
import notifyH5OpenFloatingWindowAuth from './notifyH5OpenFloatingWindowAuth';
import openFloatingWindowAuth from './openFloatingWindowAuth';
import getAppPushAuthAction from './getAppPushAuthAction';
import nativeStatusBarAction from './nativeStatusBarAction';
const api = {
    login: login,
    encryptApi: encryptApi,
    showFetusActionFloatWindow,
    closeFetusActionFloatWindow,
    getNativeFetusActionData,
    nativeFetusActionSoundSwitch,
    notifyH5MoveMentPlayStatus,
    notifyShowMovementFloatingBtn,
    forcedLogoutAccount: forcedLogoutAccount,
    setTitle: setTitle,
    openWebview: openWebview,
    closeWebview: closeWebview,
    previewImage: previewImage,
    videoPlay: videoPlay,
    showShareButton: showShareButton,
    hideShareButton: hideShareButton,
    share: share,
    setShareContent: setShareContent,
    call: call,
    catchPhoto: catchPhoto,
    uploadPhoto: uploadPhoto,
    scanQR,
    openScheme,
    openQRScanner4BindUser,
    getDeviceInfo,
    downloadBase64ImgToAlbum,
    shareSinglePlatform,
    nativeCollectStatus,
    nativeShowCollectStatus,
    updateRemindTaskStatus,
    openMiniProgram,
    shareWeixinMini,
    getAliABTest,
    launchAddThemePage,
    launchRelativeThemePage,
    nativeLaunchReply,
    getMeitunToken,
    getNativePushAction,
    nativeSharePopup,
    nativeLocation,
    nativeLuckPopup,
    updateBindUserStateSuccess,
    openMeitunReceivingAddress,
    nativeSwipeBack,
    getNeighborInfo,
    getPhotoBase64,
    uploadPhotoByBase64,
    nativeUpdateEnergy,
    nativeParlorClose,
    getCmsABTest,
    nativeCreateTopic,
    nativeGetEncyclopedia,
    motherWatchAdAction,
    openNativeTool,
    loadWebViewATAD,
    nativeGetNetState,
    nativeBafFBFeedBack,
    nativePushAction,
    nativeAudio,
    openFloatingWindowAuth,
    notifyH5OpenFloatingWindowAuth,
    getFloatingWindowAuthState,
    getAppPushAuthAction,
    nativeStatusBarAction
};
export default api;
