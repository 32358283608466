/**
 * @module bbtNative
 * @namespace bbtNative
 */
import 'core-js/features/object/assign';
import { bbtNative, bbtNativeInit } from './bbtNative.js';
import nativeFuncions from './modules';
// 脚本注入之后事件绑定
// 保证只在孕育内部执行
window.webviewAppear = function () {
    window.dispatchEvent(new Event('webviewAppear'));
};
window.webviewDisappear = function () {
    window.dispatchEvent(new Event('webviewDisappear'));
};
window.nativeMusicPlay = function () {
    window.dispatchEvent(new Event('nativeMusicPlay'));
};
window.onNetStateChange = function () {
    window.dispatchEvent(new Event('onNetStateChange'));
};
window.Base64 = function () {
    window.dispatchEvent(new Event('Base64'));
};
// 只能用USER_INFO等非可调用方法，在iOS中，WebView实例化时就会注入方法，但不会注入用户信息
if (window.USER_INFO) {
    // App内部已经注入暴露JS方法成功
    bbtNativeInit();
}
else {
    // 未注入完成面对三种情况
    // 1. 期待情况：App内部，没有注入完成，URL中包含babytree-app-id的特征字符串
    // 2. 期待情况：App内部，没有注入完成，URL中不包含babytree-app-id的特征字符串
    // 3. 意外情况：App外部，无法跟情况2区分，冗余写法
    const originalPageLoadFinish = window.pageLoadFinish;
    window.pageLoadFinish = () => {
        if (originalPageLoadFinish) {
            originalPageLoadFinish();
        }
        bbtNativeInit();
        window.pageLoadFinish = originalPageLoadFinish;
    };
}
Object.assign(bbtNative, nativeFuncions);
export default bbtNative;
