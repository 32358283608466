import { wrap } from '../bbtNative';
/**
 * 设置客户端状态栏状态
 * @param {*} status  0: 「iOS状态栏黑色,android不隐藏状态栏」  1:「iOS状态栏白色,android隐藏状态栏」
 */
export default function nativeStatusBarAction(status) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeStatusBarAction',
                params: [status + '']
            });
            return true;
        }
        if (window.nativeStatusBarAction) {
            window.nativeStatusBarAction(status + '');
            return true;
        }
        if (window.android && window.android.nativeStatusBarAction) {
            window.android.nativeStatusBarAction(status + '');
            return true;
        }
        return false;
    });
}
