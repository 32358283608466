import { isFunction } from '@bbtfe/utils';
import { wrap } from '../bbtNative';
/**
 *@apiDescription nativeLocation方法：获取定位信息 （孕育版本 8.11.0已实现）
 *@apiParams callback: {function}: 成功的回调方法
 */
export default function nativeLocation(callback) {
    wrap(function () {
        if (isFunction(callback)) {
            window.nativeLocationCallBack = function (status, longitude, latitude) {
                callback({ status, longitude, latitude });
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeLocation',
                params: []
            });
            return true;
        }
        if (window.nativeLocation && typeof window.nativeLocation == 'function') {
            window.nativeLocation();
            return true;
        }
        if (window.android && window.android.nativeLocation) {
            window.android.nativeLocation();
            return true;
        }
        return false;
    });
}
