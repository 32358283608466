import { wrap } from '../bbtNative';
/**
 *@apiDescription downloadBase64ImgToAlbum方法： 保存图片到本地相册
 *@apiParam {string} base64 图片的base64编码
 *@apiParam {function} callback 获取成功的回调
 */
export default function downloadBase64ImgToAlbum(base64, callback) {
    wrap(function () {
        if (callback && typeof callback == 'function') {
            window.downloadImageToNativeAlbumCallBack = function (result) {
                callback(result);
            };
        }
        const base64url = base64.replace(/^data:image\/(?:jpeg|jpg|png|gif);base64,/, '');
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'downloadBase64ImageToNativeAlbum',
                params: [base64url]
            });
            return true;
        }
        if (window.downloadBase64ImageToNativeAlbum &&
            typeof window.downloadBase64ImageToNativeAlbum == 'function') {
            window.downloadBase64ImageToNativeAlbum(base64url);
            return true;
        }
        if (window.android && window.android.downloadBase64ImageToNativeAlbum) {
            window.android.downloadBase64ImageToNativeAlbum(base64url);
            return true;
        }
        return false;
    });
}
