import { wrap } from '../bbtNative';
/**
*@apiDescription nativeUpdateEnergy方法：刷新客户端爱心值数据
value: 收取的爱心值
id: 访问者uid
*/
export default function nativeUpdateEnergy(value, id) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeUpdateEnergy',
                params: [value, id]
            });
            return true;
        }
        if (window.nativeUpdateEnergy &&
            typeof window.nativeUpdateEnergy == 'function') {
            window.nativeUpdateEnergy(value, id);
            return true;
        }
        if (window.android && window.android.nativeUpdateEnergy) {
            window.android.nativeUpdateEnergy(value, id);
            return true;
        }
        return false;
    });
}
