import { wrap } from '../bbtNative';
export default function closeWebview() {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeWebviewClose',
                params: []
            });
            return true;
        }
        if (window.nativeWebviewClose) {
            window.nativeWebviewClose();
            return true;
        }
        if (window.android && window.android.nativeWebviewClose) {
            window.android.nativeWebviewClose();
            return true;
        }
        return false;
    });
}
