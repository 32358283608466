import { wrap } from '../bbtNative';
/**
 *@apiDescription getCmsABTest方法：获取cms分桶信息
 *@apiParam callback:  {function}  成功的回调函数
 */
export default function getCmsABTest(callback) {
    wrap(function () {
        if (callback && typeof callback == 'function') {
            window.getABTestCallBack = function (isCms) {
                // isCms: 0,非新框架，1，新框架
                callback(isCms);
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'getCmsABTest',
                params: []
            });
            return true;
        }
        if (window.getCmsABTest && typeof window.getCmsABTest == 'function') {
            window.getCmsABTest();
            return true;
        }
        if (window.android && window.android.getCmsABTest) {
            window.android.getCmsABTest();
            return true;
        }
        return false;
    });
}
