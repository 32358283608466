import { wrap } from '../bbtNative';
/**
 * 同步绑定关系的方法
 * @param {*} status 1:绑定成功， 2：解绑成功
 */
export default function updateBindUserStateSuccess(status) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'updateBindUserStateSuccess',
                params: [status]
            });
            return true;
        }
        if (window.updateBindUserStateSuccess) {
            window.updateBindUserStateSuccess(status);
            return true;
        }
        if (window.android && window.android.updateBindUserStateSuccess) {
            window.android.updateBindUserStateSuccess(status);
            return true;
        }
        return false;
    });
}
