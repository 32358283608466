import { wrap } from '../bbtNative';
/**
 *@apiDescription shareSinglePlatform方法： 单独调起分享（微信，朋友圈，qq，空间，微博）
 *@apiParam {string} platName 平台名（'wxsession,wxtimeline,qq,qzone,sina'）
 *@apiParam {function} callback 获取成功的回调
 */
export default function shareSinglePlatform(platName, callback) {
    wrap(function () {
        if (callback && typeof callback == 'function') {
            window.thridShareSuccess = function (platformName) {
                callback(platformName);
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'shareSinglePlatform',
                params: [platName]
            });
            return true;
        }
        if (window.shareSinglePlatform &&
            typeof window.shareSinglePlatform == 'function') {
            window.shareSinglePlatform(platName);
            return true;
        }
        if (window.android && window.android.shareSinglePlatform) {
            window.android.shareSinglePlatform(platName);
            return true;
        }
        return false;
    });
}
