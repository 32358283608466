import { wrap } from '../bbtNative';
/**
 *@apiDescription getDeviceInfo方法：获取设备信息 [孕育]
 *@apiParam {function} callback 获取成功的回调
 *@apiSuccess (回调返回值) {object} result 返回设备信息
 */
export default function getDeviceInfo(callback) {
    wrap(function () {
        if (callback && typeof callback == 'function') {
            window.getDeviceInfoCallBack = function (result) {
                callback(result);
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'getDeviceInfo',
                params: []
            });
            return true;
        }
        if (window.getDeviceInfo && typeof window.getDeviceInfo == 'function') {
            window.getDeviceInfo();
            return true;
        }
        if (window.android && window.android.getDeviceInfo) {
            window.android.getDeviceInfo();
            return true;
        }
        return false;
    });
}
