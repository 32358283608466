import { wrap } from '../bbtNative';
export default function forcedLogoutAccount() {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'forcedLogoutAccount',
                params: []
            });
            return true;
        }
        if (window.forcedLogoutAccount) {
            window.forcedLogoutAccount();
            return true;
        }
        if (window.android && window.android.forcedLogoutAccount) {
            window.android.forcedLogoutAccount();
            return true;
        }
        return false;
    });
}
