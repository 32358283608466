import { isFunction } from '@bbtfe/utils';
import { wrap } from '../bbtNative';
/**
 *@apiDescription openMeitunReceivingAddress方法：获取美囤收货地址
 */
export default function openMeitunReceivingAddress(callback) {
    wrap(function () {
        if (isFunction(callback)) {
            window.openMeitunReceivingAddressFinish = function (res) {
                callback(res);
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'openMeitunReceivingAddress',
                params: []
            });
            return true;
        }
        if (window.openMeitunReceivingAddress &&
            typeof window.openMeitunReceivingAddress == 'function') {
            window.openMeitunReceivingAddress();
            return true;
        }
        if (window.android && window.android.openMeitunReceivingAddress) {
            window.android.openMeitunReceivingAddress();
            return true;
        }
        return false;
    });
}
