import { wrap } from '../bbtNative';
/**
 * 设置当前webview标题
 * @alias bbtNative.setTitle
 * @param {string} title - 标题字符串
 */
export default function setTitle(title) {
    if (!title || !title.trim()) {
        throw new Error('需要传入有效的Title');
    }
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeSetTitle',
                params: [title]
            });
            return true;
        }
        if (window.nativeSetTitle) {
            window.nativeSetTitle(title);
            return true;
        }
        if (window.android && window.android.nativeSetTitle) {
            window.android.nativeSetTitle(title);
            return true;
        }
        return false;
    });
}
