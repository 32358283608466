import { wrap } from '../bbtNative';
/**
 *@apiDescription nativeLuckPopup方法：会客厅需求中的方法 (8.7.0) 好运弹窗组件:
 *@apiParams options.data {object} 好运卡数据对象
 *@apiParams options.status {string} 1，自己；2，他人;
 *@apiParams options.uid {string}  被访问者的uid, 用于跳转到其他会客厅页面的时候，追加到url后面&uid=***
 */
export default function nativeLuckPopup(options) {
    wrap(function () {
        if (!isObject(options)) {
            throw new Error('options 必须为一个对象');
        }
        if (!isObject(options.data)) {
            throw new Error('好运卡数据对象options.data必须为object');
        }
        const opt = JSON.stringify(options.data);
        const status = options.status.toString();
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeLuckPopup',
                params: [opt, status, options.uid]
            });
            return true;
        }
        if (window.nativeLuckPopup && typeof window.nativeLuckPopup == 'function') {
            window.nativeLuckPopup(opt, status, options.uid);
            return true;
        }
        if (window.android && window.android.nativeLuckPopup) {
            window.android.nativeLuckPopup(opt, status, options.uid);
            return true;
        }
        return false;
    });
}
