import { wrap } from '../bbtNative';
/**
 *@apiDescription shareWeixinMini方法：将小程序分享到微信
 *@apiParam mini_title:  {string} 分享标题，默认值为document.title
 *@apiParam mini_id:  {string}  必传，小程序的id
 *@apiParam mini_path:  {string}  要跳转的小程序的url
 *@apiParam share_url:  {string}  分享的h5的url，为了兼容不支持小程序的旧版本微信，如果没有对应h5，就传空字符串
 *@apiParam share_imgUrl:  {string}  分享图片的url
 */
export default function shareWeixinMini(mini_title, mini_id, mini_path, share_url, share_imgUrl) {
    wrap(function () {
        if (!mini_id) {
            throw new Error('mini_id必填');
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.console.log('wk');
            window.Bridge.postMessage({
                method: 'shareWeixinMini',
                params: [mini_title, mini_id, mini_path, share_url, share_imgUrl]
            });
            return true;
        }
        if (window.shareWeixinMini && typeof window.shareWeixinMini == 'function') {
            window.shareWeixinMini(mini_title, mini_id, mini_path, share_url, share_imgUrl);
            return true;
        }
        if (window.android && window.android.shareWeixinMini) {
            window.android.shareWeixinMini(mini_title, mini_id, mini_path, share_url, share_imgUrl);
            return true;
        }
        return false;
    });
}
