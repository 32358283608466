import { wrap } from '../bbtNative';
const catchPhotoQueue = [];
/**
 *@apiDescription catchPhoto方法：从本地相册或小家相册选择照片上传
 *@api catchPhoto(options)
 *@apiParam {function} options.callback  上传照片成功的回调，必传
 *@apiSuccess (回调返回值) {object} result  返回上传成功后的id和url
 */
export default function (options) {
    if (typeof options !== 'object' || typeof options.callback !== 'function') {
        throw new Error('catchPhoto options参数类型错误，需要包含callback方法');
    }
    const { callback } = options;
    wrap(function () {
        const originalCatchPhotoFinishCall = window.catchPhotoFinishCall;
        window.catchPhotoFinishCall = function (tag, photoId, url) {
            let index;
            if (tag.indexOf('__CATCH_PHOTO_SESSIONID_') === 0) {
                index = tag.replace('__CATCH_PHOTO_SESSIONID_', '');
            }
            if (index) {
                catchPhotoQueue[index].call(undefined, {
                    photoId,
                    url
                });
            }
            window.catchPhotoFinishCall = originalCatchPhotoFinishCall;
        };
        let sessionId = '__CATCH_PHOTO_SESSIONID_' + catchPhotoQueue.length;
        if (window.Bridge && window.Bridge.postMessage) {
            catchPhotoQueue.push(callback);
            window.Bridge.postMessage({
                method: 'nativeCatchPhoto',
                params: [sessionId, '100', '100']
            });
            window.Bridge.postMessage({
                method: 'catchPhoto',
                params: [sessionId, '100', '100']
            });
            return true;
        }
        const catchPhotoFn = window.nativeCatchPhoto || window.catchPhoto;
        if (catchPhotoFn && typeof catchPhotoFn == 'function') {
            catchPhotoQueue.push(callback);
            //后面两个参数没有用，但是native中有定义需要传，如果不传会调用不成功
            catchPhotoFn(sessionId, '100', '100');
            return true;
        }
        if (window.android) {
            const androidCatchPhotoFn = window.android.nativeCatchPhoto || window.android.catchPhoto;
            if (androidCatchPhotoFn) {
                catchPhotoQueue.push(callback);
                androidCatchPhotoFn(sessionId, '100', '100');
                return true;
            }
        }
        return false;
    });
}
