import { isFunction } from '@bbtfe/utils';
import { wrap } from '../bbtNative';
/**
 *@apiDescription getMeitunToken方法：获取美囤token (7.9.30)
 */
export default function getMeitunToken(callback) {
    wrap(function () {
        if (isFunction(callback)) {
            window.getMeitunTokenCallBack = function (res) {
                callback(res);
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'getMeitunToken',
                params: []
            });
            return true;
        }
        if (window.getMeitunToken && typeof window.getMeitunToken == 'function') {
            window.getMeitunToken();
            return true;
        }
        if (window.android && window.android.getMeitunToken) {
            window.android.getMeitunToken();
            return true;
        }
        return false;
    });
}
