import { wrap } from '../bbtNative';
/**
 H5通知APP开启权限
 **/
export default function openFloatingWindowAuth() {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'openFloatingWindowAuth',
                params: []
            });
            return true;
        }
        if (window.openFloatingWindowAuth &&
            typeof window.openFloatingWindowAuth == 'function') {
            window.openFloatingWindowAuth();
            return true;
        }
        if (window.android && window.android.openFloatingWindowAuth) {
            window.android.openFloatingWindowAuth();
            return true;
        }
        return false;
    });
}
