import { isFunction } from '@bbtfe/utils';
import { wrap } from '../bbtNative';
/**
 *@apiDescription nativeCollectStatus方法：知识收藏互通：（7.9.18）
 *@apiParam status:  {string}  '0'未收藏；‘1’已收藏
 */
export default function nativeCollectStatus(status, callback) {
    wrap(function () {
        if (isFunction(callback)) {
            window.nativeCollectStatusCallBack = function (status) {
                callback(status);
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeCollectStatus',
                params: [status]
            });
            return true;
        }
        if (window.nativeCollectStatus) {
            window.nativeCollectStatus(status);
            return true;
        }
        if (window.android && window.android.nativeCollectStatus) {
            window.android.nativeCollectStatus(status);
            return true;
        }
        return false;
    });
}
