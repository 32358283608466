import { wrap } from '../bbtNative';
/**
 *@apiDescription launchRelativeThemePage方法：相关主题弹框 （7.9.16）
 *@apiParam contentId:  {string}  内容id
 *@apiParam contentType:  {string}  内容类型 1-达人文章 2-帖子 3-知识 4-问答 5-专家答 6-妈妈看 7-专家开讲 8-关注人发布文章 9-关注人发布帖子 10-关注人回答问题 11-关注人转发文章 12-关注人转发帖子 13-关注人转发知识 14-关注人转发问答 15-首页达人 16-主题主页可能感兴趣的人
 */
export default function launchRelativeThemePage(contentId, contentType) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'launchRelativeThemePage',
                params: [contentId, contentType]
            });
            return true;
        }
        if (window.launchRelativeThemePage &&
            typeof window.launchRelativeThemePage == 'function') {
            window.launchRelativeThemePage(contentId, contentType);
            return true;
        }
        if (window.android && window.android.launchRelativeThemePage) {
            window.android.launchRelativeThemePage(contentId, contentType);
            return true;
        }
        return false;
    });
}
