import { compareVersions } from './common';
const RegExpMap = {
    Edge: /Edge/i,
    Wechat: /MicroMessenger/,
    Weibo: /Weibo/,
    QQ: /\sQQ\//,
    MQQBrowser: /MQQBrowser/i,
    Qzone: /\sQzone\//,
    Mobile: /Mobile/,
    Android: /Android/,
    iOS: /iPhone|iPad|iPod/,
    iPhone: /iPhone/,
    isAppleDevice: /iPad|iPhone|iPod|Mac OS X/,
    isBot: /Baiduspider|Sogou\s\w+\sspider|Yisouspider|Googlebot|Bingbot|360Spider/,
    Tablet: /(iPad|Tablet)|(Android.*Chrome(?!.*Mobile))/,
    UC: /UCBrowser/,
    Sogou: /Sogou/,
    Qihoo: /QHBrowser/,
    Baidu: /baidubrowser/,
    BaiduApp: /baidubox/,
    Safari: /^((?!chrome|android).)*safari/i,
    GoogleBot: /Googlebot/,
    DingTalk: /DingTalk/,
    AlipayClient: /AlipayClient/,
    NewsArticle: /NewsArticle/,
    pregnancy: /(pregnancy|AliUnionMall)/,
    lama: /lama/ // 宝宝树小时光
};
export function UAParser(userAgent = window.navigator.userAgent) {
    let uaMap = {};
    uaMap = (Object.keys(RegExpMap) || []).reduce((total, item) => {
        total[item] = RegExpMap[item].test(userAgent);
        return total;
    }, uaMap);
    if (/MSIE\s(\d+)\.(\d+)/i.exec(userAgent) ||
        /Trident.*rv[.:\s](\d+)\.(\d+)/i.exec(userAgent)) {
        uaMap['IE'] = true;
    }
    if (uaMap.iPhone && window.screen.height >= 812) {
        uaMap.iPhoneX = true;
    }
    uaMap.origin = userAgent;
    return uaMap;
}
export function isPregnancy(userAgent = window.navigator.userAgent) {
    const referrer = document.referrer;
    return (/(pregnancy|AliUnionMall)/.test(userAgent) ||
        referrer.indexOf('nativeapp.babytree.com') > -1);
}
export function isLama(userAgent = window.navigator.userAgent) {
    return /lama/.test(userAgent);
}
export function isApp(userAgent = window.navigator.userAgent) {
    const referrer = document.referrer;
    // 孕育 + 小时光 都认为是站内app
    return (isPregnancy(userAgent) ||
        isLama(userAgent) ||
        referrer.indexOf('nativeapp.babytree.com') > -1);
}
export function isWechat(userAgent = window.navigator.userAgent) {
    return /MicroMessenger/.test(userAgent);
}
export function isWechatNotLessThan705(userAgent = window.navigator.userAgent) {
    const match = userAgent.match(/(micromessenger)\/([\w\.]+)/i);
    if (match && match[2] && compareVersions(match[2], '7.0.5') >= 0)
        return true;
    return false;
}
export function isIOS(userAgent = window.navigator.userAgent) {
    return /iPhone|iPad|iPod/.test(userAgent);
}
export function isQQ(userAgent = window.navigator.userAgent) {
    return /\sQQ\//.test(userAgent);
}
export function isSafari(userAgent = window.navigator.userAgent) {
    return /^((?!chrome|android).)*safari/i.test(userAgent);
}
export function isLocalIP(ip = window.location.host) {
    return /(^127\.)|(^192\.168\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)|(^::1$)|(^[fF][cCdD])/.test(ip);
}
export function getEnv(host = window.location.host) {
    if (host.indexOf('localhost') > -1 || isLocalIP(host)) {
        return 'local';
    }
    else if (host.indexOf('dev') > -1) {
        return 'dev';
    }
    else if (host.indexOf('test') > -1) {
        return 'test';
    }
    else if (host.indexOf('pre') > -1) {
        return 'pre';
    }
    else {
        return 'prod';
    }
}
export function getLibOrigin() {
    const host = getEnv(window.location.host) == 'prod'
        ? '//h5cdn.babytreeimg.com'
        : '//h5cdn-1.babytree-test.com';
    return host;
}
export function getLibURL(options = {}) {
    const { name, version, type = 'js', mini = false } = options;
    return `${getLibOrigin()}/h5_fe_lib/release/${name}/${version}/index.${mini ? 'min.' : ''}${type}`;
}
export function getNetInfo() {
    // https://caniuse.com/#feat=netinfo
    // https://wicg.github.io/netinfo/
    // https://www.davidbcalhoun.com/2010/using-navigator-connection-android/
    // https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/effectiveType
    const connection = window.navigator.connection ||
        window.navigator.mozConnection ||
        window.navigator.webkitConnection;
    let type = 'unknown';
    if (!connection) {
        return type;
    }
    if (connection.type) {
        type = connection.type;
        if (/^\d+$/.test(type)) {
            switch (type) {
                case connection.ETHERNET:
                    type = 'ETHERNET';
                    break;
                case connection.WIFI:
                    type = 'WIFI';
                    break;
                case connection.CELL:
                    // type = 'CELL';
                    type = '2g';
                    break;
                case connection.CELL_2G:
                    type = 'CELL_2G';
                    break;
                case connection.CELL_3G:
                    type = 'CELL_3G';
                    break;
                case connection.CELL_4G:
                    type = 'CELL_4G';
                    break;
                //case connection.UNKNOWN:
                default:
                    type = 'UNKNOWN';
            }
        }
    }
    else if (connection.effectiveType) {
        type = connection.effectiveType;
    }
    type = type.toLowerCase();
    // 1：wifi 2：4g 3：3g 4：2.5g 5：2g 6：手机自带网络 7：未知网络 8：没有网络（断网） 9：其他
    if (type.indexOf('wifi') >= 0) {
        type = '1';
    }
    else if (type.indexOf('4g') >= 0) {
        type = '2';
    }
    else if (type.indexOf('3g') >= 0) {
        type = '3';
    }
    else if (type.indexOf('2g') >= 0) {
        type = '5';
    }
    else if (type.indexOf('unknown') >= 0) {
        type = '7';
    }
    return type.toLowerCase();
}
