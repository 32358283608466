import { wrap, bbtNative } from '../bbtNative';
import Queue from '../Queue';
const encryptApiCallQueue = new Queue();
let isEncryptApiCalling = false;
function encryptApi() {
    if (isEncryptApiCalling || encryptApiCallQueue.isEmpty()) {
        return;
    }
    isEncryptApiCalling = true;
    var arg = encryptApiCallQueue.shift(), params = arg.params, callback = arg.callback, originalNativeGetApiEncryptionFinishCall = window.nativeGetApiEncryptionFinishCall;
    // 跟客户端同事沟通，nativeGetApiEncryption本质是在当前的JS环境中执行
    // 不存在异步操作的场景，是完全的同步操作
    // 下面的函数中，即时顺序执行后将回调还原
    // 但客户端同事说的跟实际情况不相符，用队列序列化执行
    window.nativeGetApiEncryptionFinishCall = function (data) {
        callback(data);
        isEncryptApiCalling = false;
        window.nativeGetApiEncryptionFinishCall = originalNativeGetApiEncryptionFinishCall;
        // console.log('enc', params, data, encryptApiCallQueue.length);
        encryptApi();
    };
    //if (window.webkit.messageHandlers && window.webkit.messageHandlers['nativeGetApiEncryption']) {
    if (window.Bridge && window.Bridge.postMessage) {
        //window.webkit.messageHandlers['nativeGetApiEncryption'].postMessage([JSON.stringify(params)]);
        window.Bridge.postMessage({
            method: 'nativeGetApiEncryption',
            params: [JSON.stringify(params)]
        });
        return true;
    }
    if (window.nativeGetApiEncryption) {
        window.nativeGetApiEncryption(JSON.stringify(params));
        return true;
    }
    if (window.android && window.android.nativeGetApiEncryption) {
        window.android.nativeGetApiEncryption(JSON.stringify(params));
        return true;
    }
}
/**
 * 参数加密
 * @alias bbtNative.encryptApi
 * @param {object} params - 参数
 * @param {function} callback - 回调函数
 */
export default function encryptApiWarpper(params, callback) {
    if (params === undefined || JSON.stringify(params) === '{}') {
        return;
        //params = {};
    }
    if (Object.prototype.toString.call(params) !== '[object Object]') {
        throw new Error('params类型参数错误，应该是一个Object或者不传这个参数');
    }
    encryptApiCallQueue.push({
        params: params,
        callback: callback
    });
    // console.log('enc', params, encryptApiCallQueue.length);
    wrap(function () {
        encryptApi();
    });
}
if (!bbtNative.appinfo.id) {
    bbtNative.ready(() => {
        encryptApiWarpper({ notEmpty: 'notEmpty' }, (data) => {
            bbtNative.appinfo.id =
                /(?:&|)app_id=([\d\w\-.]+)(?:&|$)/.test(data) && RegExp.$1;
            bbtNative.appinfo.version =
                /(?:&|)version=([\d\w\-.]+)(?:&|$)/.test(data) && RegExp.$1;
        });
    });
}
