import { wrap } from '../bbtNative';
/**
 *@apiDescription nativeSwipeBack方法：是否侧滑返回
 *@apiParams： 0：禁止侧滑返回
 */
export default function nativeSwipeBack(type) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeSwipeBack',
                params: [type]
            });
            return true;
        }
        if (window.nativeSwipeBack && typeof window.nativeSwipeBack == 'function') {
            window.nativeSwipeBack(type);
            return true;
        }
        if (window.android && window.android.nativeSwipeBack) {
            window.android.nativeSwipeBack(type);
            return true;
        }
        return false;
    });
}
