import { wrap } from '../bbtNative';
/**
 H5获取APP推送权限
 **/
export default function getAppPushAuthAction(options) {
    wrap(function () {
        const getAppPushAuthActionCallBack = window.getAppPushAuthActionCallBack;
        window.getAppPushAuthActionCallBack = function (data) {
            options.callback(data);
            window.getAppPushAuthActionCallBack = getAppPushAuthActionCallBack;
        };
        const config = options.config;
        const str = JSON.stringify(config);
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'getAppPushAuthAction',
                params: [str]
            });
            return true;
        }
        if (window.nativeSwipeBack && typeof window.nativeSwipeBack == 'function') {
            window.getAppPushAuthAction(str);
            return true;
        }
        if (window.android && window.android.getAppPushAuthAction) {
            window.android.getAppPushAuthAction(str);
            return true;
        }
        return false;
    });
}
