import { wrap } from '../bbtNative';
/**
 * 二维码扫描来绑定用户
 */
export default function openQRScanner4BindUser(callback) {
    wrap(function () {
        if (callback && typeof callback == 'function') {
            window.openQRScanner4BindUserCallBack = function (state, url) {
                callback({ state, url });
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'openQRScanner4BindUser',
                params: []
            });
            return true;
        }
        if (window.openQRScanner4BindUser &&
            typeof window.openQRScanner4BindUser == 'function') {
            window.openQRScanner4BindUser();
            return true;
        }
        if (window.android && window.android.openQRScanner4BindUser) {
            window.android.openQRScanner4BindUser();
            return true;
        }
        return false;
    });
}
