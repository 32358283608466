import { wrap } from '../bbtNative';
export default function previewImage(src) {
    if (!src || !src.trim() || !/^https?:\/\//.test(src)) {
        throw new Error('需要传入有效的图片地址');
    }
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativePhotoBrowse',
                params: [src]
            });
            return true;
        }
        if (window.nativePhotoBrowse) {
            window.nativePhotoBrowse(src);
            return true;
        }
        if (window.android && window.android.nativePhotoBrowse) {
            window.android.nativePhotoBrowse(src);
            return true;
        }
        return false;
    });
}
