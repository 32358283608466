import { wrap } from '../bbtNative';
/**
 *@apiDescription nativePushAction：注册本地push方法
 *@options：
 id: 'goodluck'，
 push_action: '1' ,//1:注册；0:注销
 time: '', //push 首次时间戳
 title: '' //push 标题
 content：“”//push 内容
 url：“” //路由
 repeat_type：‘1’ //'0' '1'
 callBack: f( data = {
    id : ''.
    switch : '1'.
 }) //
 **/
export default function nativePushAction(options) {
    wrap(function () {
        const originallocalPushActionCallBack = window.localPushActionCallBack;
        window.localPushActionCallBack = function (data) {
            options.callback(data);
            window.localPushActionCallBack = originallocalPushActionCallBack;
        };
        const config = options.config;
        const str = JSON.stringify(config);
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'localPushAction',
                params: [str]
            });
            return true;
        }
        if (window.localPushAction && typeof window.localPushAction == 'function') {
            window.localPushAction(str);
            return true;
        }
        if (window.android && window.android.localPushAction) {
            window.android.localPushAction(str);
            return true;
        }
        return false;
    });
}
