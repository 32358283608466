import { wrap } from '../bbtNative';
/**
*@apiDescription nativeSharePopup方法：H5分享引导浮层(能不能吃)
*@apiParams type: {string}: type 1帖子 2能不能吃 (后续如果再新加其他内容弹窗再添加)
H5判断满足停留15s或滑动2屏调用, 客户端判断在线开关和是否已弹出过的状态
*/
export default function nativeSharePopup(type) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeSharePopup',
                params: [type]
            });
            return true;
        }
        if (window.nativeSharePopup &&
            typeof window.nativeSharePopup == 'function') {
            window.nativeSharePopup(type);
            return true;
        }
        if (window.android && window.android.nativeSharePopup) {
            window.android.nativeSharePopup(type);
            return true;
        }
        return false;
    });
}
