import { wrap } from '../bbtNative';
//素材位id（int）、内容类型（int）、内容id（String），素材位id目前测试环境我创建了478（踩）和477（赞>    ）用来测试，线上会不一样，内容类型（1是文章。2是知识。3是工具。4是帖子），内容id就是相应的id  nativeBafFBFeed    Back(materialId, contentType, contentId)
export default function nativeBafFBFeedBack(id, contentType, contentId) {
    if (!id) {
        throw new Error('需要传入有效的素材位id');
    }
    if (!contentType) {
        throw new Error('需要传入有效的内容类型');
    }
    if (!contentId || !contentId.trim()) {
        throw new Error('需要传入有效的内容id');
    }
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeBafFBFeedBack',
                params: [id, contentType, contentId]
            });
            return true;
        }
        if (window.nativeBafFBFeedBack) {
            window.nativeBafFBFeedBack(id, contentType, contentId);
            return true;
        }
        if (window.android && window.android.nativeBafFBFeedBack) {
            window.android.nativeBafFBFeedBack(id, contentType, contentId);
            return true;
        }
        return false;
    });
}
