import { wrap } from '../bbtNative';
/**
 *@apiDescription nativeCollect方法：原生收藏功能
 *@apiParam options.show:  {string} 是否显示收藏， 'yes', 'no'
 *@apiParam options.status:  {number} 收藏状态
 *@apiParam options.id:  {string} collectID
 *@apiParam options.type {string} 类型
 * eg: window.nativeShowCollectStatus('yes', collectStatus1, window.collectId, 'special_article');
 */
export default function nativeCollect(options) {
    wrap(function () {
        if (!isObject(options)) {
            throw new Error('options必须为object类型');
        }
        const show = options.show ? options.show : 'yes';
        const status = options.status ? options.status : '1';
        const id = options.id ? options.id : '';
        const type = options.type ? options.type : '';
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeShowCollectStatus',
                params: [show, status, id, type]
            });
            return true;
        }
        if (window.nativeShowCollectStatus) {
            window.nativeShowCollectStatus(show, status, id, type);
            return true;
        }
        if (window.android && window.android.nativeShowCollectStatus) {
            window.android.nativeShowCollectStatus(show, status, id, type);
            return true;
        }
        return false;
    });
}
