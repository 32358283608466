export function isFunction(func) {
    return typeof func === 'function';
}
export const isArrayLike = (arrayLike) => arrayLike != null && typeof arrayLike[Symbol.iterator] === 'function';
export const isArray = (array) => Object.prototype.toString.call(array) === '[object Array]';
export function isObject(obj) {
    if (obj && Object.prototype.toString.call(obj) === '[object Object]') {
        return true;
    }
    return false;
}
