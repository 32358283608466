import { wrap } from '../bbtNative';
/**
 * 拨打电话
 * @alias bbtNative.call
 * @param {string} mobile - 电话号码
 */
export default function call(mobile) {
    if (!mobile || !mobile.trim()) {
        throw new Error('需要传入有效的电话号码');
    }
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.console.log('phone:', mobile, '调用没成功需iOS解决');
            window.Bridge.postMessage({
                method: 'nativeCallByNumber',
                params: [mobile]
            });
            return true;
        }
        if (window.nativeCallByNumber) {
            window.nativeCallByNumber(mobile);
            return true;
        }
        if (window.android && window.android.nativeCallByNumber) {
            window.android.nativeCallByNumber(mobile);
            return true;
        }
        return false;
    });
}
