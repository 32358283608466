import { isFunction } from '@bbtfe/utils';
import { wrap } from '../bbtNative';
/**
 app开启权限后，通知H5
 **/
export default function notifyH5OpenFloatingWindowAuth(callback) {
    wrap(function () {
        if (isFunction(callback)) {
            window.notifyH5OpenFloatingWindowAuthCallBack = function (isOpen) {
                callback(isOpen);
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'notifyH5OpenFloatingWindowAuth',
                params: []
            });
            return true;
        }
        if (window.notifyH5OpenFloatingWindowAuth &&
            typeof window.notifyH5OpenFloatingWindowAuth == 'function') {
            window.notifyH5OpenFloatingWindowAuth();
            return true;
        }
        if (window.android && window.android.notifyH5OpenFloatingWindowAuth) {
            window.android.notifyH5OpenFloatingWindowAuth();
            return true;
        }
        return false;
    });
}
