import { wrap } from '../bbtNative';
/**
 *@apiDescription nativeParlorClose方法：关闭所有会客厅相关页面
 */
export default function nativeParlorClose() {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeParlorClose',
                params: []
            });
            return true;
        }
        if (window.nativeParlorClose &&
            typeof window.nativeParlorClose == 'function') {
            window.nativeParlorClose();
            return true;
        }
        if (window.android && window.android.nativeParlorClose) {
            window.android.nativeParlorClose();
            return true;
        }
        return false;
    });
}
