import { wrap } from '../bbtNative';
export default function previewImage(src) {
    if (!src || !src.trim()) {
        throw new Error('需要传入有效的视频地址');
    }
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'videoPlay',
                params: [src]
            });
            return true;
        }
        if (window.videoPlay) {
            window.videoPlay(src);
            return true;
        }
        if (window.android && window.android.videoPlay) {
            window.android.videoPlay(src);
            return true;
        }
        return false;
    });
}
