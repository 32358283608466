import { wrap } from '../bbtNative';
/**
 *@apiDescription uploadPhoto方法：上传图片
 *@api uploadPhoto(options)
 *@apiParam {string} max 上传图片的数量，最大为9，最小为1，默认为1
 *@apiParam {function} callback 上传成功的回调，必传
 */
export default function (options) {
    if (typeof options !== 'object' || typeof options.callback !== 'function') {
        throw new Error('uploadPhoto options参数类型错误，需要包含callback方法');
    }
    let max = Math.min(options.max || 0, 9);
    max = Math.max(max, 1);
    wrap(function () {
        const originalgetPhotoBase64FinishCall = window.getPhotoBase64FinishCall;
        window.getPhotoBase64FinishCall = function (status, tag, data) {
            if (data && data.replace) {
                // 安卓需要特殊处理空白副
                data = data.replace(/\s/g, '');
            }
            options.callback(status, tag, data);
            window.getPhotoBase64FinishCall = originalgetPhotoBase64FinishCall;
        };
        const sessionId = options.sessionId || +new Date() + '';
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'getPhotoBase64',
                params: [sessionId, max, '1000000', '1000000']
            });
            return true;
        }
        if (window.getPhotoBase64 && typeof window.getPhotoBase64 == 'function') {
            //1000000意思是不压缩
            window.getPhotoBase64(sessionId, max, '1000000', '1000000');
            return true;
        }
        if (window.android && window.android.getPhotoBase64) {
            window.android.getPhotoBase64(sessionId, max, '1000000', '1000000');
            return true;
        }
        return false;
    });
}
