import { isFunction, isObject } from '@bbtfe/utils';
import { wrap } from '../bbtNative';
/**
*@apiDescription nativeLaunchReply方法：评论模块交互方法：（>=7.9.7）
*options = {
    type: 'post',  //String, 发送请求的方式：post || get
    maxlength: 200,  //int, 最多输入字数
    placeholder: '请输入评论内容',   //String
    value: '这里传入点击取消时，输入框中的文案',   //String  客户端还未实现，备用
    url: hostUrl + '/app/pregnancy/knowledge/create_comment_ajax', //api请求地址
    contentName: 'content', //评论发布框中发布内容的key值
    data: {
        //questionId: 1,  //int
        //answerId: 1,  //int
        //replayId: 1,   //int
        knowledge_id: knowledgeId,
        login_string: loginString
    },    //data,   api需要传的参数都放到这里，不需要的不放
    expression: 0, //int; 1，可发表情；0，不可发表情
    img: 0, //int; 1，可发图片；0，不可发图片
    audio: 0  //int; 1，可发音频；0，不可发音频
};
*/
export default function nativeLaunchReply(options, callback) {
    wrap(function () {
        if (!isObject(options)) {
            throw new Error('options必须为object类型');
        }
        if (isFunction(callback)) {
            window.nativeLaunchReplyCallBack = function (opt) {
                let result = {};
                if (window.navigator.userAgent.indexOf('Android') === -1) {
                    result = JSON.parse(decodeURIComponent(opt));
                }
                else {
                    result = JSON.parse(opt.replace('json":"{', 'json":{').replace('}"}', '}}'));
                }
                callback(result);
            };
        }
        if (window.navigator.userAgent.indexOf('Android') !== -1) {
            options = JSON.stringify(options);
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeLaunchReply',
                params: [options]
            });
            return true;
        }
        if (window.nativeLaunchReply &&
            typeof window.nativeLaunchReply == 'function') {
            window.nativeLaunchReply(options);
            return true;
        }
        if (window.android && window.android.nativeLaunchReply) {
            window.android.nativeLaunchReply(options);
            return true;
        }
        return false;
    });
}
