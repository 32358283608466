import { isApp, getQueries, isFunction } from '@bbtfe/utils';
import dispatcher from './Dispatcher';
const querys = getQueries();
export const bbtNative = {
    querys: querys,
    /**
     * app初始化状态
     * @static
     * @type {Boolean}}
     */
    isReady: false,
    /**
     * app初始化完成回调
     * @static
     * @param {function} callback - 初始化完成回调
     */
    ready: function (callback) {
        var me = this;
        if (this.isReady) {
            // 保证异步编程模型
            window.setTimeout(function () {
                callback.call(undefined, me);
            }, 0);
        }
        else {
            dispatcher.one('bbt-ready', function () {
                callback.call(undefined, me);
            });
        }
    },
    /**
     * 判断当前环境是否是app环境
     * @static
     * @returns {Boolean} - 是否是app环境
     */
    isApp: function () {
        return isApp();
    },
    /**
     * app信息
     * @static
     * @property {object}  appinfo
     * @property {string}  appinfo.id - id
     * @property {string}  appinfo.version - 版本号
     */
    appinfo: {
        id: querys['babytree-app-id'],
        version: querys['babytree-app-version']
    },
    /**
     * 判断当前用户登录态
     * @static
     * @returns {Boolean} - 当前用户是否登录
     */
    isLogin: function () {
        if (this.userinfo && this.userinfo.loginString)
            return true;
        return false;
    },
    callNativeFunction: function (options = {}, ...rest) {
        const { functionName, callbackName, callback } = options;
        if (callbackName && callback && isFunction(callback)) {
            const __originalCallBack = window[callbackName];
            window[callbackName] = function (data) {
                callback(data);
                window[callbackName] = __originalCallBack;
            };
        }
        const params = rest.map((param) => JSON.stringify(param));
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: functionName,
                params
            });
            return true;
        }
        if (window[functionName] && isFunction(window[functionName])) {
            window[functionName](...params);
            return true;
        }
        if (window.android &&
            window.android[functionName] &&
            isFunction(window.android[functionName])) {
            window.android[functionName](...params);
            return true;
        }
        return false;
    },
    userinfo: undefined
};
export const bbtNativeInit = function () {
    bbtNative.userinfo = Object.assign(Object.assign({}, bbtNative.userinfo), window.USER_INFO);
    bbtNative.isReady = true;
    dispatcher.emit('bbt-ready');
};
let sequence = 0;
export const wrap = function (callback) {
    if (bbtNative.isReady) {
        return callback.call(undefined, bbtNative);
    }
    sequence += 1;
    window.setTimeout(function () {
        sequence -= 1;
        bbtNative.ready(callback);
    }, sequence * 35);
};
