import { wrap } from '../bbtNative';
// * @param pageType 视图类型
// * @param divId 往该div位置插入
export default function loadWebViewATAD(type, id) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'loadWebViewATAD',
                params: [type, id]
            });
            return true;
        }
        if (window.loadWebViewATAD) {
            window.loadWebViewATAD(type, id);
            return true;
        }
        if (window.android && window.android.loadWebViewATAD) {
            window.android.loadWebViewATAD(type, id);
            return true;
        }
        return false;
    });
}
