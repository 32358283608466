import { wrap } from '../bbtNative';
/**
 *@apiDescription nativeCreateTopic方法：创建话题
 *@apiParam options:  {object}  配置对象
 *@apiParam options.group_id
 *@apiParam options.group_name
 *@apiParam options.subject_code
 *@apiParam options.subject_name
 *@apiParam options.title
 *@apiParam options.content
 *@apiParam options.topic_post_image
 *@apiParam options.source
 *@apiParam options.trace_id
 *@apiParam options.is_anonymous
 *@apiParam options.mention_id
 *@apiParam options.mention_name
 *@apiParam options.content_tip
 *@apiParam options.subject_type
 *@apiParam options.subject_source
 */
export default function nativeCreateTopic(options) {
    wrap(function () {
        const str = JSON.stringify(options);
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeCreateTopic',
                params: [str]
            });
            return true;
        }
        if (window.nativeCreateTopic &&
            typeof window.nativeCreateTopic == 'function') {
            window.nativeCreateTopic(str);
            return true;
        }
        if (window.android && window.android.nativeCreateTopic) {
            window.android.nativeCreateTopic(str);
            return true;
        }
        return false;
    });
}
