/*
 * 队列
 */
class Queue {
    constructor() {
        this.container = [];
    }
    push(item) {
        this.container.push(item);
    }
    shift() {
        return this.container.shift();
    }
    peek() {
        return this.container[this.container.length - 1];
    }
    clear() {
        this.container.length = 0;
    }
    isEmpty() {
        return this.container.length === 0;
    }
    isFull() {
        return false;
    }
}
export default Queue;
