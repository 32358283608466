import { wrap } from '../bbtNative';
export default function scanQR() {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'scanQR',
                params: []
            });
            return true;
        }
        if (window.scanQR) {
            window.scanQR();
            return true;
        }
        if (window.android && window.android.scanQR) {
            window.android.scanQR();
            return true;
        }
        return false;
    });
}
