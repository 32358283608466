import { wrap } from '../bbtNative';
export default function openWebview(title, url) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'adAction',
                params: [title, url]
            });
            return true;
        }
        if (window.adAction) {
            window.adAction(title, url);
            return true;
        }
        if (window.android && window.android.adAction) {
            window.android.adAction(title, url);
            return true;
        }
        return false;
    });
}
