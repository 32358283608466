import { isFunction } from '@bbtfe/utils';
import { wrap } from '../bbtNative';
/**
 H5获取APP全局弹窗权限
 **/
export default function getFloatingWindowAuthState(callback) {
    wrap(function () {
        if (isFunction(callback)) {
            window.getFloatingWindowAuthStateCallBack = function (isHaveAuth) {
                callback(isHaveAuth);
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'getFloatingWindowAuthState',
                params: []
            });
            return true;
        }
        if (window.getFloatingWindowAuthState &&
            typeof window.getFloatingWindowAuthState == 'function') {
            window.getFloatingWindowAuthState();
            return true;
        }
        if (window.android && window.android.getFloatingWindowAuthState) {
            window.android.getFloatingWindowAuthState();
            return true;
        }
        return false;
    });
}
