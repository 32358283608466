import { wrap } from '../bbtNative';
/**
 *@apiDescription uploadPhoto方法：上传图片
 *@api uploadPhoto(options)
 *@apiParam {string} max 上传图片的数量，最大为9，最小为1，默认为1
 *@apiParam {function} callback 上传成功的回调，必传
 */
export default function (options) {
    if (typeof options !== 'object' || typeof options.callback !== 'function') {
        throw new Error('uploadPhoto options参数类型错误，需要包含callback方法');
    }
    let max = Math.min(options.max || 0, 9);
    max = Math.max(max, 1);
    wrap(function () {
        const originalUploadPhotoFinishCall = window.uploadPhotoFinishCall;
        window.uploadPhotoFinishCall = function (status, tag, data) {
            // if (window.parseInt(status, 10) === -1 ) {
            //     data = [];
            // }
            // if (typeof data === 'string') {
            //     data = window.JSON.parse(data);
            // }
            // let i, len;
            // for (i = 0, len = data.length; i < len; i += 1) {
            //     delete data[i].data.album_id;
            //     delete data[i].data.user_id;
            // }
            options.callback(status, tag, data);
            window.uploadPhotoFinishCall = originalUploadPhotoFinishCall;
        };
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'uploadPhoto',
                params: ['webview', max, '1000000', '1000000']
            });
            return true;
        }
        if (window.uploadPhoto && typeof window.uploadPhoto == 'function') {
            //1000000意思是不压缩
            window.uploadPhoto('webview', max, '1000000', '1000000');
            return true;
        }
        if (window.android && window.android.uploadPhoto) {
            window.android.uploadPhoto('webview', max, '1000000', '1000000');
            return true;
        }
        return false;
    });
}
