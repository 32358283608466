import { wrap } from '../bbtNative';
/**
 * @apiDescription openScheme方法：scheme跳转 [孕育 小时光]
 * @param {*} options
 *    options.scheme    {string} scheme 必传,要跳转页面的scheme
 *    options.callback  {string} callback 成功的回调
 */
export default function openScheme(options) {
    if (!options ||
        Object.prototype.toString.call(options) !== '[object Object]') {
        throw new Error('options必须为object类型');
    }
    if (options.callback && typeof options.callback == 'function') {
        window.openSchemeFinish = function (result) {
            options.callback(result);
        };
    }
    const scheme = options.scheme ? options.scheme : '';
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'openScheme',
                params: [scheme]
            });
            return true;
        }
        if (window.openScheme && typeof window.openScheme == 'function') {
            window.openScheme(scheme);
            return true;
        }
        if (window.android && window.android.openScheme) {
            window.android.openScheme(scheme);
            return true;
        }
        return false;
    });
}
