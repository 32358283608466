import { wrap } from '../bbtNative';
export default function motherWatchAdAction(id, url) {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'motherWatchAdAction',
                params: [id, url]
            });
            return true;
        }
        if (window.motherWatchAdAction) {
            window.motherWatchAdAction(id, url);
            return true;
        }
        if (window.android && window.android.motherWatchAdAction) {
            window.android.motherWatchAdAction(id, url);
            return true;
        }
        return false;
    });
}
