import { wrap } from '../bbtNative';
/**
 *@apiDescription updateRemindTaskStatus方法：通知客户端更新任务状态，目前是爸爸版关爱提醒任务用到
 *@apiParam id:  {string} taskid
 *@apiParam status:  {number}  完成状态（1 完成  0 未完成）
 */
export default function updateRemindTaskStatus(id, status) {
    wrap(function () {
        if (!id) {
            throw new Error('id不能为空');
        }
        let _status = status == 1 ? status : 0;
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'updateRemindTaskStatus',
                params: [id, _status]
            });
            return true;
        }
        if (window.updateRemindTaskStatus &&
            typeof window.updateRemindTaskStatus == 'function') {
            window.updateRemindTaskStatus(id, _status);
            return true;
        }
        if (window.android && window.android.updateRemindTaskStatus) {
            window.android.updateRemindTaskStatus(id, _status);
            return true;
        }
        return false;
    });
}
