import { wrap } from '../bbtNative';
// 数胎动开启全局浮层
export function showFetusActionFloatWindow(startTime) {
    wrap(function () {
        if (window.showFetusActionFloatWindow) {
            window.showFetusActionFloatWindow(startTime + '');
            return true;
        }
        if (window.android && window.android.showFetusActionFloatWindow) {
            window.android.showFetusActionFloatWindow(startTime + '');
            return true;
        }
        return false;
    });
}
// 数胎动关闭全局浮层
export function closeFetusActionFloatWindow() {
    wrap(function () {
        if (window.closeFetusActionFloatWindow) {
            window.closeFetusActionFloatWindow();
            return true;
        }
        if (window.android && window.android.closeFetusActionFloatWindow) {
            window.android.closeFetusActionFloatWindow();
            return true;
        }
        return false;
    });
}
// 数胎动获取全局浮层点击数据
export function getNativeFetusActionData(callback) {
    wrap(function () {
        if (callback && typeof callback == 'function') {
            const originalgetNativeFetusActionDataCallBack = window.getNativeFetusActionDataCallBack;
            window.getNativeFetusActionDataCallBack = function (taps = '[]') {
                const list = JSON.parse(taps);
                callback(list.map((item) => {
                    return window.parseFloat(item);
                }));
                window.getNativeFetusActionDataCallBack = originalgetNativeFetusActionDataCallBack;
            };
        }
        if (window.getNativeFetusActionData) {
            window.getNativeFetusActionData();
            return true;
        }
        if (window.android && window.android.getNativeFetusActionData) {
            window.android.getNativeFetusActionData();
            return true;
        }
        if (callback && typeof callback == 'function') {
            callback([]);
        }
        return false;
    });
}
// 数胎动设置全局浮层点击音效开关
export function nativeFetusActionSoundSwitch(isOpen) {
    wrap(function () {
        if (window.nativeFetusActionSoundSwitch) {
            window.nativeFetusActionSoundSwitch(isOpen);
            return true;
        }
        if (window.android && window.android.nativeFetusActionSoundSwitch) {
            window.android.nativeFetusActionSoundSwitch(isOpen);
            return true;
        }
        return false;
    });
}
// app胎教音乐播放状态通知H5
export function notifyH5MoveMentPlayStatus(callback) {
    wrap(function () {
        window.notifyH5MoveMentPlayStatusCallBack = function (status, name) {
            callback(status, name);
        };
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'notifyH5MoveMentPlayStatus',
                params: []
            });
            return true;
        }
        if (window.notifyH5MoveMentPlayStatus) {
            window.notifyH5MoveMentPlayStatus();
            return true;
        }
        if (window.android && window.android.notifyH5MoveMentPlayStatus) {
            window.android.notifyH5MoveMentPlayStatus();
            return true;
        }
        return false;
    });
}
export function notifyShowMovementFloatingBtn(status) {
    if (window.notifyShowMovementFloatingBtn) {
        window.notifyShowMovementFloatingBtn(status);
        return true;
    }
    if (window.android && window.android.notifyShowMovementFloatingBtn) {
        window.android.notifyShowMovementFloatingBtn(status);
        return true;
    }
    return false;
}
