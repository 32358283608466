import { isFunction, isObject } from '@bbtfe/utils';
import { wrap } from '../bbtNative';
export default function nativeGetNetState(callback) {
    wrap(function () {
        if (isFunction(callback)) {
            window.nativeGetNetStateFinishCall = function (data) {
                if (isObject(data)) {
                    callback(data);
                }
            };
        }
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'nativeGetNetState',
                params: []
            });
            return true;
        }
        if (window.nativeGetNetState &&
            typeof window.nativeGetNetState == 'function') {
            window.nativeGetNetState();
            return true;
        }
        if (window.android && window.android.nativeGetNetState) {
            window.android.nativeGetNetState();
            return true;
        }
        return false;
    });
}
