import { wrap, bbtNative } from '../bbtNative';
function setSharedCallback(callback) {
    // 分享成功之后调用，有可能调用顺序不安全
    var originalShared;
    if (window.thridShareSuccess) {
        originalShared = window.thridShareSuccess;
    }
    if (window.shareActionFinishCall) {
        originalShared = window.shareActionFinishCall;
    }
    function shared(type, status) {
        if (parseInt(status, 10) === 0) {
            status = 'error';
        }
        else {
            status = 'success';
        }
        window.shareActionFinishCall = window.thridShareSuccess = originalShared;
        // window.thridShareSuccess = window.shareActionFinishCall = shared;
        callback(type, status);
    }
    window.thridShareSuccess = window.shareActionFinishCall = shared;
}
export function setShareContent(options) {
    // 从孕育6.5.0之后，用imageUrl设置分享图片，不再支持imageBase64
    // 从孕育7之后某个版本，可以支持分享图片
    if (options.type === 'picture' && !options.imageBase64) {
        throw new Error('分享图片需要传入要分享图片的Base64编码');
    }
    const callback = options && options.onShared;
    if (callback) {
        setSharedCallback(callback);
    }
    let imageUrl = options.imageUrl;
    if (!imageUrl) {
        // 默认取当前页面中第一张图片为分享缩略图，不考虑图片是否可见
        let img = document.querySelector('img[src]:not([src=""])');
        if (img) {
            imageUrl = img.src;
        }
    }
    let share = {};
    // isSame为true时，参数需完全按照原生的参数传入
    if (options.isSame) {
        share = options;
    }
    else {
        share = {
            share_url: options.url ||
                location.href
                    .replace(/&babytree-(?:app|client)-\w+=[\d\w\-.]+/g, '')
                    .replace(/\?babytree-(?:app|client)-\w+=[\d\w\-.]+&/g, '?')
                    .replace(/&bpreg_brithday=[\d\w\-.]+/g, '')
                    .replace(/\?bpreg_brithday=[\d\w\-.]+&/g, '?'),
            share_title: options.title || document.title,
            share_content: options.content || options.title || document.title,
            image_base64: imageUrl,
            onShared: options.onShared
        };
    }
    share.success = function () {
        window.console.log('分享成功，调用window.share.success, 不写share.success这个方法，android会报错');
    };
    // App中版本没有做向下兼容，导致会使用image_base64的url做分享图片
    if (options.type === 'picture') {
        const appinfo = bbtNative.appinfo;
        if (appinfo.id === 'pregnancy' &&
            appinfo.version &&
            parseInt(appinfo.version.replace(/\./g, ''), 10) >= 764) {
            share.image_base64 = '';
        }
        share.share_source = options.source || 'webview';
        share.share_type = 'shareSinglePicture';
        share.image_data_base64 = options.imageBase64.replace(/^data:image\/(?:jpeg|jpg|png|gif);base64,/, '');
    }
    window.share = share;
    console.log(window.share);
}
export function showShareButton() {
    wrap(function () {
        //if (window.webkit.messageHandlers && window.webkit.messageHandlers['showShareButton']) {
        if (window.Bridge && window.Bridge.postMessage) {
            //window.webkit.messageHandlers['showShareButton'].postMessage(['yes']);
            window.Bridge.postMessage({
                method: 'showShareButton',
                params: ['yes']
            });
            return true;
        }
        if (window.showShareButton) {
            window.showShareButton('yes');
            return true;
        }
        if (window.android && window.android.showShareButton) {
            window.android.showShareButton('yes');
            return true;
        }
        return false;
    });
}
export function hideShareButton() {
    wrap(function () {
        if (window.Bridge && window.Bridge.postMessage) {
            window.Bridge.postMessage({
                method: 'showShareButton',
                params: ['no']
            });
            return true;
        }
        if (window.showShareButton) {
            window.showShareButton('no');
            return true;
        }
        if (window.android && window.android.showShareButton) {
            window.android.showShareButton('no');
            return true;
        }
        return false;
    });
}
/*
 * target: []    分享到哪儿？示例：['wechat']
 */
export function share(options) {
    window.console.log(options);
    var callback = options && (options.onShared || options.success);
    if (callback) {
        window.console.log('存在回调');
        setSharedCallback(callback);
    }
    wrap(function () {
        /*目前看孕育中没有shareToWeiChat这个方法,也不曾用过
            if (options && options.target && options.target[0] === 'wechat' && window.shareToWeiChat) {
                const share = window.shareToWeiChat;
                if (share) {
                    share(window.share.share_title,
                        window.share.share_content,
                        window.share.image_base64,
                        window.share.share_url);
                    return true;
                }
            */
        if (options && options.target) {
            //分享到不同的端wxsession,wxtimeline,qq,qzone,sina
            var sharePlatform = 'wxsession, wxtimeline, qq,qzone, sina';
            var target = options.target;
            if (window.navigator.userAgent.indexOf('iPhone OS') > -1) {
                window.installSharePlatform = function (data) {
                    sharePlatform = data;
                };
            }
            if (sharePlatform.indexOf(target) > -1) {
                window.console.log('target:' + target, '能分享的端', sharePlatform);
                if (window.Bridge && window.Bridge.postMessage) {
                    window.Bridge.postMessage({
                        method: 'shareSinglePlatform',
                        params: [target]
                    });
                    return true;
                }
                if (window.shareSinglePlatform) {
                    window.shareSinglePlatform(target);
                    return true;
                }
                if (window.android && window.android.shareSinglePlatform) {
                    window.android.shareSinglePlatform(target);
                    return true;
                }
            }
        }
        else {
            //if (window.webkit.messageHandlers && window.webkit.messageHandlers['shareAction']) {
            if (window.Bridge && window.Bridge.postMessage) {
                window.console.log('shareIos');
                //window.webkit.messageHandlers['shareAction'].postMessage([]);
                window.Bridge.postMessage({
                    method: 'shareAction' || 'actionShare',
                    params: []
                });
                return true;
            }
            const share = window.actionShare || window.shareAction;
            if (share) {
                share();
                return true;
            }
            if (window.android) {
                const shareAndroid = window.android.actionShare || window.android.shareAction;
                window.console.log('shareAndroid');
                shareAndroid();
                return true;
            }
        }
        return false;
    });
}
